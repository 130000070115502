import React from 'react'
import styled from 'styled-components'
import { NotificationOutlined } from '@ant-design/icons'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
	gap: 10px;

  padding: 24px 16px;
  background: #fff;
  border-radius: 16px;

  .anticon {
    font-size: 30px;

    svg {
      fill: #ff6ea1;
    }
  }

  .slider {
    overflow: hidden;

    .marquee {
      color: #000;
      font-size: 20px;

      white-space: nowrap;
      display: inline-block;
      animation: scroll 30s linear infinite;
    }

    .marquee::after {
      content: ' ';
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(20%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

const NotificationSection = () => {
  return (
    <Wrapper>
      <NotificationOutlined />
      <div className="slider">
        <p className="marquee">
          Register and receive a free mining machine deposit of 68,000 TRX. The minimum deposit to activate the mining machine is
          10 TRX, and the minimum withdrawal is 5 TRX. The daily income received can be reinvested or withdrawn.
        </p>
      </div>
    </Wrapper>
  )
}

export default NotificationSection
