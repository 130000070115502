import { Carousel, Image } from 'antd'
import React from 'react'

const HeroSlider = () => {
  return (
	<Carousel infinite autoplay>
		<Image src='/images/home/silder-1.png' preview={false} width="100%" className='hero-image'/>
		<Image src='/images/home/silder-2.png' preview={false} width="100%" className='hero-image'/>
		<Image src='/images/home/silder-3.png' preview={false} width="100%" className='hero-image'/>
	</Carousel>
  )
}

export default HeroSlider