import { Col, Image, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 30px;
  padding: 30px 10px;
  background: #fff;
  box-shadow: 1px 1px 10px #00000030;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 430px) {
    gap: 20px;
  }

  h2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: 430px) {
      font-size: 32px;
    }
  }

  .ant-row {
    width: 100%;
  }
`


const PartnerSection = () => {
  return (
    <Wrapper>
      <h2>Partners</h2>

      <Row gutter={[20, 20]}>
        {
          [1,2,3,4,5,6,7,8,9].map(x => (
            <Col span={8} key={x}>
		            <Image src={`/images/home/partner-${x}.png`} preview={false} width="100%" />
            </Col>
          ))
        }
      </Row>
    </Wrapper>
  )
}

export default PartnerSection
