/* eslint-disable react/react-in-jsx-scope */
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const WrapperFooter = styled.div`
  z-index: 99;
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);

  width: 100%;
  max-width: 576px;
  height: 78px;
  margin: 0 auto;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #eef2fc;
  border-radius: 60px;
  background-image: url(/images/bg/footer-bg.png);
  background-size: 101% auto;
  background-repeat: no-repeat;

  @media screen and (min-width: 370px) {
    background-size: 95.5% auto;
  }

  @media screen and (min-width: 430px) {
    height: 108px;
  }
`
export const Dock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    height: 100%;
    flex: 1;
    cursor: pointer;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    padding: 8px 12px;
    color: #adadad;
    position: relative;

    svg {
      width: 20px;
      height: 20px;

      @media screen and (min-width: 430px) {
        width: 36px;
        height: 36px;
      }
    }

    img {
        width: 36px;
        height: 36px;

      @media screen and (min-width: 430px) {
        width: 60px;
        height: 60px;
      }
    }

    &.active {
      color: #ff3b79;

      ::after {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 40%;
        left: 50%;
        transform: translate(-50%, 60%);

        width: 24px;
        height: 24px;
        border-radius: 100%;
        box-shadow: 1px 1px 40px #ff3b794d;
        background: #ff3b794d;
        filter: blur(6px);
      }
    }

    * {
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 18px;

      @media screen and (min-width: 430px) {
        font-size: 16px;
      }
    }

    &:nth-child(3) {
      position: relative;
      gap: 0;

      @media screen and (min-width: 430px) {
        gap: 4px;
        bottom: 6px;
      }
    }
  }
`

export default function Footer() {
  const { pathname  } = useLocation()

  return (
    <WrapperFooter>
      <Dock>
        <div className={pathname === '/' ? 'active' : ''}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.02767 8.25C9.02767 6.5925 10.3702 5.25 12.0277 5.25C13.6852 5.25 15.0277 6.6 15.0277 8.25C15.0277 9.9 13.6852 11.25 12.0277 11.25C10.3702 11.25 9.02767 9.9075 9.02767 8.25ZM10.5277 8.25C10.5277 9.075 11.2027 9.75 12.0277 9.75C12.8527 9.75 13.5277 9.075 13.5277 8.25C13.5277 7.425 12.8527 6.75 12.0277 6.75C11.2027 6.75 10.5277 7.425 10.5277 8.25Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.4001 20.79L21.8601 17.04C21.8076 16.6575 21.7551 16.2975 21.4401 16.0275C21.1251 15.75 20.7576 15.75 20.3751 15.75H15.7926C17.3226 13.6725 18.7776 10.8675 18.7776 7.7475C18.7776 4.305 15.7476 1.5 12.0276 1.5C8.30761 1.5 5.27761 4.305 5.27761 7.7475C5.27761 10.8675 6.73261 13.6725 8.26261 15.75H3.68011C3.29761 15.75 2.93011 15.75 2.61511 16.0275C2.30011 16.2975 2.24761 16.6575 2.19511 17.0325V17.04L1.65511 20.79L1.65012 20.827C1.58453 21.3136 1.52843 21.7296 1.85761 22.11C2.19511 22.5 2.63761 22.5 3.14011 22.5H20.9151C21.4176 22.5 21.8601 22.5 22.1976 22.11C22.5254 21.7226 22.4684 21.2982 22.4015 20.8L22.4001 20.79ZM12.0276 3C9.13261 3 6.77761 5.13 6.77761 7.7475C6.77761 10.92 8.59261 13.8225 10.1751 15.75C10.6626 16.35 11.1351 16.86 11.5176 17.25C11.7126 17.445 11.8851 17.61 12.0276 17.745C12.1701 17.61 12.3426 17.445 12.5376 17.25C12.9201 16.86 13.3926 16.3575 13.8801 15.75C15.4626 13.8225 17.2776 10.92 17.2776 7.7475C17.2776 5.13 14.9226 3 12.0276 3ZM3.68011 17.25L3.14011 21H20.9151L20.3751 17.25H14.5851C13.8351 18.105 13.1676 18.7425 12.7551 19.11C12.6051 19.245 12.3876 19.44 12.0276 19.44C11.6676 19.44 11.4501 19.245 11.3001 19.11C10.8876 18.7425 10.2201 18.105 9.47011 17.25H3.68011Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.02767 8.25C9.02767 6.5925 10.3702 5.25 12.0277 5.25C13.6852 5.25 15.0277 6.6 15.0277 8.25C15.0277 9.9 13.6852 11.25 12.0277 11.25C10.3702 11.25 9.02767 9.9075 9.02767 8.25ZM10.5277 8.25C10.5277 9.075 11.2027 9.75 12.0277 9.75C12.8527 9.75 13.5277 9.075 13.5277 8.25C13.5277 7.425 12.8527 6.75 12.0277 6.75C11.2027 6.75 10.5277 7.425 10.5277 8.25Z"
              fill="currentColor"
            />
          </svg>
          <span>Home</span>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M20.55 6.75H4.8C4.60108 6.75 4.41032 6.67098 4.26967 6.53033C4.12902 6.38968 4.05 6.19891 4.05 6C4.05 5.80109 4.12902 5.61032 4.26967 5.46967C4.41032 5.32902 4.60108 5.25 4.8 5.25H18.3C18.4989 5.25 18.6897 5.17098 18.8303 5.03033C18.971 4.88968 19.05 4.69891 19.05 4.5C19.05 4.30109 18.971 4.11032 18.8303 3.96967C18.6897 3.82902 18.4989 3.75 18.3 3.75H4.8C4.20326 3.75 3.63096 3.98705 3.20901 4.40901C2.78705 4.83097 2.55 5.40326 2.55 6V18C2.55 18.5967 2.78705 19.169 3.20901 19.591C3.63096 20.0129 4.20326 20.25 4.8 20.25H20.55C20.9478 20.25 21.3294 20.092 21.6107 19.8107C21.892 19.5294 22.05 19.1478 22.05 18.75V8.25C22.05 7.85218 21.892 7.47064 21.6107 7.18934C21.3294 6.90804 20.9478 6.75 20.55 6.75ZM20.55 18.75H4.8C4.60108 18.75 4.41032 18.671 4.26967 18.5303C4.12902 18.3897 4.05 18.1989 4.05 18V8.12156C4.29082 8.20693 4.54449 8.25037 4.8 8.25H20.55V18.75ZM16.05 13.125C16.05 12.9025 16.116 12.685 16.2396 12.5C16.3632 12.315 16.5389 12.1708 16.7445 12.0856C16.95 12.0005 17.1762 11.9782 17.3945 12.0216C17.6127 12.065 17.8132 12.1722 17.9705 12.3295C18.1278 12.4868 18.235 12.6873 18.2784 12.9055C18.3218 13.1238 18.2995 13.35 18.2144 13.5555C18.1292 13.7611 17.985 13.9368 17.8 14.0604C17.615 14.184 17.3975 14.25 17.175 14.25C16.8766 14.25 16.5905 14.1315 16.3795 13.9205C16.1685 13.7095 16.05 13.4234 16.05 13.125Z"
              fill="currentColor"
            />
          </svg>
          <span>Earning</span>
        </div>
        <div>
          <img src="/images/bg/footer-bg-tron.png" alt="" />
          <span>Investment</span>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M21.67 6.20158L13.42 1.68752C13.1996 1.56572 12.9518 1.50183 12.7 1.50183C12.4482 1.50183 12.2004 1.56572 11.98 1.68752L3.73 6.20345C3.49439 6.33237 3.29772 6.52217 3.16051 6.75304C3.02331 6.98391 2.95061 7.24739 2.95 7.51595V16.4822C2.95061 16.7508 3.02331 17.0142 3.16051 17.2451C3.29772 17.476 3.49439 17.6658 3.73 17.7947L11.98 22.3106C12.2004 22.4324 12.4482 22.4963 12.7 22.4963C12.9518 22.4963 13.1996 22.4324 13.42 22.3106L21.67 17.7947C21.9056 17.6658 22.1023 17.476 22.2395 17.2451C22.3767 17.0142 22.4494 16.7508 22.45 16.4822V7.51689C22.4499 7.24785 22.3774 6.98379 22.2402 6.75238C22.103 6.52096 21.906 6.33072 21.67 6.20158ZM12.7 3.00002L20.2319 7.12502L17.4409 8.65314L9.90812 4.52814L12.7 3.00002ZM12.7 11.25L5.16812 7.12502L8.34625 5.38502L15.8781 9.51002L12.7 11.25ZM4.45 8.43752L11.95 12.5419V20.5847L4.45 16.4831V8.43752ZM20.95 16.4794L13.45 20.5847V12.5456L16.45 10.9041V14.25C16.45 14.4489 16.529 14.6397 16.6697 14.7803C16.8103 14.921 17.0011 15 17.2 15C17.3989 15 17.5897 14.921 17.7303 14.7803C17.871 14.6397 17.95 14.4489 17.95 14.25V10.0828L20.95 8.43752V16.4785V16.4794Z"
              fill="currentColor"
            />
          </svg>
          <span>Share</span>
        </div>

        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9 21.75C16.6279 21.75 19.65 19.5674 19.65 16.875C19.65 14.1826 19.65 12 12.9 12C6.14999 12 6.14999 14.1826 6.14999 16.875C6.14999 19.5674 9.17207 21.75 12.9 21.75ZM18.15 16.875C18.15 17.6184 17.7356 18.4266 16.7947 19.1061C15.8559 19.7842 14.4837 20.25 12.9 20.25C11.3163 20.25 9.94412 19.7842 9.00526 19.1061C8.06441 18.4266 7.64999 17.6184 7.64999 16.875C7.64999 15.3714 7.72481 14.8337 8.07776 14.4646C8.4538 14.0715 9.55347 13.5 12.9 13.5C16.2465 13.5 17.3462 14.0715 17.7222 14.4646C18.0752 14.8337 18.15 15.3714 18.15 16.875Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9 10.5C14.9711 10.5 16.65 8.82107 16.65 6.75C16.65 4.67893 14.9711 3 12.9 3C10.8289 3 9.14999 4.67893 9.14999 6.75C9.14999 8.82107 10.8289 10.5 12.9 10.5ZM15.15 6.75C15.15 7.99264 14.1426 9 12.9 9C11.6574 9 10.65 7.99264 10.65 6.75C10.65 5.50736 11.6574 4.5 12.9 4.5C14.1426 4.5 15.15 5.50736 15.15 6.75Z"
              fill="currentColor"
            />
          </svg>
          <span>My</span>
        </div>
      </Dock>
    </WrapperFooter>
  )
}
