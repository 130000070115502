import { Form, Input } from 'antd'
import BaseButton from 'components/BaseButton'
import BackButton from 'components/BaseButton/BackButton'
import { Text } from 'poodlefi-libs-uikit'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const FormWrapper = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media screen and (min-width: 430px) {
    width: 80%;
  }

  .ant-form {
    width: 100%;

    label {
      color: #191919;
      font-weight: bold;
      font-size: 16px;
    }

    input {
      border-radius: 16px;
      color: #ff82af;

      ::placeholder {
        color: #837d7f;
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 11px;
      border-radius: 16px;
      background-color: #fff1f5 !important;
      border-color: transparent;

      input {
        border: none !important;
        box-shadow: none !important;
      }
    }

    .ant-form-item-explain {
      div {
        font-size: 10px;
      }
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;

  @media screen and (min-width: 430px) {
    width: 80%;
    padding-bottom: 30px;
  }
`

const ForgotPasswordPage = () => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [timeCountDown, setTimeCountDown] = useState(0)

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ form.validateFields ~ values:', values)
    })
  }

  const handleReSendCode = () => {
    if (!timeCountDown) {
      setTimeCountDown(60)
    }
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  return (
    <Wrapper>
      <BackButton title="Update Password" />

      <FormWrapper>
        <Form form={form} layout="vertical">
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Email is require' },
              {
                type: 'email',
                message: 'Invalid Email',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Verification code is require',
              },
            ]}
          >
            <Input placeholder="Verification Code" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Password is require' }]}>
            <Input.Password placeholder="Password" autoComplete="false" />
          </Form.Item>
        </Form>
      </FormWrapper>

      <ButtonWrapper>
        <BaseButton
          background="#ffecf2"
          textColor="#ff1964"
          onClick={handleReSendCode}
        >
          {!timeCountDown ? 'Click to get code' : <>{timeCountDown}s</>}
        </BaseButton>
        <BaseButton onClick={handleSubmit}>Confirm Change</BaseButton>

        <Text
          textAlign="center"
          color="#fe6ea2"
          fontSize="16px"
          style={{ cursor: 'pointer', width: '100%' }}
          onClick={() => history.push('/')}
        >
          Home
        </Text>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default ForgotPasswordPage
