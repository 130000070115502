import React from 'react'
import QRCode from 'react-qr-code'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'
import styled from 'styled-components'
import BackButton from 'components/BaseButton/BackButton'
import { Flex } from 'poodlefi-libs-uikit'
import BaseButton from 'components/BaseButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  > svg {
    width: 160px;
    height: 160px;

    @media screen and (min-width: 430px) {
      width: 200px;
      height: 200px;
    }
  }

  .address {
    font-size: 12px;
    font-weight: bold;
  }

  .label {
    color: #333;
  }

  .red {
    color: #eb0909;
    font-size: 12px;
  }
`

const Payment = () => {
  return (
    <Wrapper>
      <BackButton title="Payment" />

      <QRCode value="TP5TBkfXpj5vSbdUcqEjfsgrYnXeuZGDo1" />

      <Flex flexDirection="column" style={{ gap: 8 }}>
        <p className="label">Address</p>
        <p className="address">TP5TBkfXpj5vSbdUcqEjfsgrYnXeuZGDo1</p>
      </Flex>

      <CopyToClipboard onCopy={() => message.success('Copied success')} text="TP5TBkfXpj5vSbdUcqEjfsgrYnXeuZGDo1">
        <BaseButton background="#ffecf2" textColor="#ff1964">
          Copy Address
        </BaseButton>
      </CopyToClipboard>

      <BaseButton onClick={() => true}>Recharge Completed</BaseButton>

      <Flex flexDirection="column" style={{ gap: 8 }}>
        <p className="label">Description</p>
        <p className="red">
          1.If the account is not credited for a long time, please click &quot;Recharge Completed&quot; again.
        </p>
      </Flex>
    </Wrapper>
  )
}

export default Payment
