import { Image } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  margin-top: 30px;
  padding: 30px 10px;
  background: #fff;
  box-shadow: 1px 1px 10px #00000030;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 430px) {
    gap: 20px;
  }

  h2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: 430px) {
      font-size: 32px;
    }
  }
`

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 430px) {
    gap: 20px;
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;

      .ant-image-img {
        width: 24px;
        height: 24px;

        @media screen and (min-width: 430px) {
          width: 40px;
          height: 40px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .percent {
        padding: 8px 16px;
        border-radius: 8px;
        text-align: center;
        color: #fff;
        font-weight: bold;

        &.red {
          background: #dd345b;
        }

        &.green {
          background: #03ad91;
        }
      }
    }

    p {
      font-size: 12px;

      @media screen and (min-width: 430px) {
        font-size: 16px;
      }
    }
  }
`

const MarketSection = () => {
  return (
    <Wrapper>
      <h2>Market Center</h2>

      <List>
        {[
          {
            name: 'BNB',
            symbol: 'BNB',
            price: 564.06,
            percent: 2,
          },
          {
            name: 'Bitcoin',
            symbol: 'BTC',
            price: 69464.57,
            percent: 2,
          },
          {
            name: 'Dogecoin',
            symbol: 'DOG',
            price: 0.17,
            percent: 2,
          },
          {
            name: 'Ethereum',
            symbol: 'ETH',
            price: 2429.13,
            percent: 2,
          },
          {
            name: 'Litecoin',
            symbol: 'LTC',
            price: 66.06,
            percent: 2,
          },
          {
            name: 'TRON',
            symbol: 'TRX',
            price: 0.16,
            percent: 2,
          },
        ].map((item, i) => (
          <div className="item" key={item.symbol}>
            <div className="left">
              <Image src={`/images/tokens/${item.symbol.toLowerCase()}.png`} preview={false} />
              <p>{item.name} / USDT</p>
            </div>

            <div className="right">
              <p>{addCommasToNumber(item.price)}</p>
              <p className={`percent ${i % 2 === 0 ? 'red' : 'green'}`}>{addCommasToNumber(item.percent)} %</p>
            </div>
          </div>
        ))}
      </List>
    </Wrapper>
  )
}

export default MarketSection
