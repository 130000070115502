import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ResetCSS } from 'poodlefi-libs-uikit'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'
import GlobalStyle from './style/Global'
import App from './pages/App'
// import ApplicationUpdater from './state/application/updater'
// import ListsUpdater from './state/lists/updater'
// import MulticallUpdater from './state/multicall/updater'
// import TransactionUpdater from './state/transactions/updater'
import ToastListener from './components/ToastListener'
import Providers from './Providers'
import 'inter-ui'
import 'antd/dist/antd.css'
import './i18n'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

if ('ethereum' in window) (window.ethereum as any).autoRefreshOnNetworkChange = false

window.addEventListener('error', () => {
  localStorage?.removeItem('redux_localstorage_simple_lists')
})

ReactDOM.render(
  <StrictMode>
    <Providers>
      <>
        {/* <ListsUpdater />
        <ApplicationUpdater />
        <TransactionUpdater />
        <MulticallUpdater /> */}
        <ToastListener />
      </>
      <ResetCSS />
      <GlobalStyle />
      <App />
    </Providers>
  </StrictMode>,
  document.getElementById('root')
)
