import { Image } from 'antd'
import BaseButton from 'components/BaseButton'
import BackButton from 'components/BaseButton/BackButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  .wrap-item-token {
    min-height: 50vh;
    margin: 24px -20px;

    .item-token {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      background: rgba(255, 221, 230, 0.6);
      padding: 12px;
      margin-bottom: 1px;
      cursor: pointer;

      span {
        font-size: 13px;
        line-height: 1;
        color: #9397a4;
      }
    }
  }
`

const RechargePage = () => {
  const history = useHistory()

  return (
    <Wrapper>
      <BackButton title="Accepted tokens" />

      <div className="wrap-item-token">
        <div className="item-token" onClick={() => history.push('/recharge/payment')}>
          <Image src="/images/coins/TRX.png" preview={false} width="50px" />

          <div>
            <p>TRX</p>
            <span>Exchange Rate 1:1 TRX</span>
          </div>
        </div>

        <div className="item-token" onClick={() => history.push('/recharge/payment')}>
          <Image src="/images/coins/tether_trc20.png" preview={false} width="50px" />

          <div>
            <p>TRC20-USDT</p>
            {/* <span>Exchange Rate 1:1 TRX</span> */}
          </div>
        </div>
      </div>

      <BaseButton onClick={() => history.push('/recharge/payment')}>Confirm</BaseButton>
    </Wrapper>
  )
}

export default RechargePage
