import React from 'react'
import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: rgb(254, 110, 162);

  .anticon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

const BackButton = ({ title }) => {
  const history = useHistory()

  return (
    <Wrapper>
      <ArrowLeftOutlined onClick={() => history.goBack()} />
      {title}
    </Wrapper>
  )
}

export default BackButton
