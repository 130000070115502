import { formatCode } from 'helpers/CommonHelper'
import React from 'react'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  margin-top: 30px;
  padding: 30px 10px;
  background: #ffe8ef;
  box-shadow: 1px 1px 10px #00000030;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 430px) {
    gap: 20px;
  }

  h2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: 430px) {
      font-size: 32px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .list {
      width: 100%;
      max-height: 400px;
      overflow-y: scroll;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      p {
        font-size: 16px;

        &.right {
          text-align: right;
        }

        &.sub {
          color: #999;
        }

        @media screen and (min-width: 430px) {
          font-size: 20px;
        }
      }
    }
  }
`

const EarningSection = () => {
  return (
    <Wrapper>
      <h2>Earnings Withdrawal</h2>

      <div className="content">
        <div className="flex">
          <p className="sub">Account</p>
          <p className="sub right">Amount</p>
        </div>

        <div className="list">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((x) => (
            <div className="flex" key={x}>
              <p>{formatCode('0x2f842b09f046e8f5152487e3b6463a3e3460f248', 3, 3, '*****')}</p>
              <p className="right">{addCommasToNumber(1000)} TRX</p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default EarningSection
