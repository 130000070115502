/* eslint-disable react/react-in-jsx-scope */
import { BellOutlined, CustomerServiceOutlined } from '@ant-design/icons'
import { Image } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const WrapperHeader = styled.div`
  position: relative;
  z-index: 10;

  .wrap-content-header {
    width: 100%;
    margin-left: 0;
  }

  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;

    .wrap-title {
      display: flex;
      align-items: center;
    }

    .content-right {
      display: flex;
      align-items: center;
      flex-direction: flex-end;
      gap: 8px;
    }
  }
`

const IconWrapper = styled.div`
  padding: 8px;
  background: #f5c7eb;
  border-radius: 50%;

  .anticon {
    font-size: 24px;

    svg {
      fill: #000;
    }
  }
`

export default function Header() {
  const history = useHistory()

  return (
    <WrapperHeader>
      <div className="wrap-content-header">
        <div className="content-header">
          <div className="wrap-title" onClick={() => history.push("/")}>
            <Image src="/images/main-logo.png" preview={false} width={200} />
          </div>

          <div className="content-right">
            <IconWrapper>
              <BellOutlined />
            </IconWrapper>

            <IconWrapper>
              <CustomerServiceOutlined />
            </IconWrapper>
          </div>
        </div>
      </div>
    </WrapperHeader>
  )
}
