import { Col, Image, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 30px;
  padding: 30px 10px;
  background: #fff;
  box-shadow: 1px 1px 10px #00000030;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: 430px) {
      font-size: 32px;
    }
  }

  .ant-row {
    width: 100%;
  }

  .more {
	text-align: center;
    color: #ababab;
	font-size: 16px;
	cursor: pointer;

	@media screen and (min-width: 430px) {
      font-size: 20px;
    }
  }
`

const InvestmentCard = styled.div`
  background: linear-gradient(#e9b5cc, #eb74a8);
  padding: 12px 8px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: 430px) {
    padding: 16px;
  }

  .ant-image-img {
    border-radius: 8px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &-header {
      color: #fff;
      font-size: 14px;

      @media screen and (min-width: 430px) {
        font-size: 18px;
      }
    }

    &-body {
      p {
        font-size: 12px;

        @media screen and (min-width: 430px) {
          font-size: 16px;
        }
      }
    }
  }
`

const InvestmentSection = () => {
  return (
    <Wrapper>
      <h2>Smart Investment</h2>

      <Row gutter={[10, 20]}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
          <Col span={12} key={x}>
            <InvestmentCard>
              <Image src="/images/home/silder-2.png" preview={false} />
              <div className="card-content">
                <p className="card-content-header">Mining Pools/VIP</p>
                <div className="card-content-body">
                  <p>Investment Amount:</p>
                  <p>10-1999TRX</p>
                  <p>Daily Earnings:</p>
                  <p>8%</p>
                </div>
              </div>
            </InvestmentCard>
          </Col>
        ))}
      </Row>

	  <div className='more'>View More</div>
    </Wrapper>
  )
}

export default InvestmentSection
