/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import HeroSlider from './components/HeroSlider'
import BalanceSection from './components/BalanceSection'
import NotificationSection from './components/NotificationSection'
import AppMenuSection from './components/AppMenuSection'
// import AccountSection from './components/AccountSection'
import InvestmentSection from './components/InvestmentSection'
import WhitePaperSection from './components/WhitePaperSection'
import MarketSection from './components/MarketSection'
import PartnerSection from './components/PartnerSection'
import EarningSection from './components/EarningSection'

const WrapDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .hero-image {
    border-radius: 20px;
  }
`

const DashboardPage = () => {
  return (
    <WrapDashboardPage>
      <HeroSlider />
      <BalanceSection />
      <NotificationSection />
      <AppMenuSection />
      {/* <AccountSection /> */}
      <InvestmentSection />
      <WhitePaperSection />
      <MarketSection />
      <EarningSection />
      <PartnerSection />
    </WrapDashboardPage>
  )
}

export default DashboardPage
