import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'
import { Box } from 'poodlefi-libs-uikit'

const Wrapper = styled(Box)<{
  background?: string
  borderRadius?: string
  width?: string
  secondary?: boolean
  borderColor?: string
  textColor?: string
}>`
  width: ${({ width }) => width || '100%'} ;
  cursor: pointer;
  background: ${({ background }) => background || '#ff1964'};
  border: 1px solid ${({ borderColor }) => borderColor || 'transparent'};
  border-radius: ${({ borderRadius }) => borderRadius || '100px'};
  color: ${({ textColor }) => textColor || '#fff'};
  position: relative;
  font-size: 14px;
  padding: 12px 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
  }

  &:disabled {
    background-color: #262b34;
  }

  & > div {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    justify-content: center;
    color: ${({ textColor }) => textColor || '#000'};

    @media (min-width: 991px) {
      line-height: 24px;
    }
  }

  &[data-hover='true'],
  &[data-loading='true'] {
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[disabled],
  &[disabled='true'] {
    cursor: not-allowed;
    filter: grayscale(1);
  }
`

const BaseButton: React.FC<any> = ({
  loading = false,
  disabled = false,
  disableHover = false,
  background,
  borderRadius,
  textColor,
  width,
  onClick,
  children,
  secondary = false,
  borderColor,
  ...props
}) => {
  return (
    <Wrapper
      as="button"
      background={background}
      borderColor={borderColor}
      borderRadius={borderRadius}
      width={width}
      data-loading={loading.toString()}
      data-hover={disableHover.toString()}
      disabled={disabled}
      secondary={secondary}
      textColor={textColor}
      onClick={() => {
        if (!loading && !disabled && onClick) onClick()
      }}
      {...props}
    >
      {children}
      {loading && <Spin spinning />}
    </Wrapper>
  )
}

export default BaseButton
