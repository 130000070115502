/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { useHistory, useLocation } from 'react-router-dom'
import { authService } from 'services'
import Routes from 'pages/Routes'

const StyleLayout = styled.div`
  position: relative;
  z-index: 0;
  max-width: 100%;

  > .content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);

    &:not(.no-padding) {
      padding-bottom: 100px;
    }

    .content-page {
      padding: 0 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
`

const CONFIG_DO_NOT_HAVE_FOOT = [
  Routes.signIn,
  Routes.signUp,
  Routes.forgotPassword,
]

const CONFIG_PUBLIC_ROUTES = [
  Routes.home,
  Routes.signIn,
  Routes.signUp,
  Routes.forgotPassword,
]

const CustomLayout: React.FC<React.PropsWithChildren<{ children: React.ReactNode }>> = ({ children }) => {
  const history = useHistory()
  const { isDark } = useTheme()
  const { pathname  } = useLocation()

  const token = authService.getToken()

  const isPublic = CONFIG_PUBLIC_ROUTES.includes(pathname)
  const isNotHasFooter = CONFIG_DO_NOT_HAVE_FOOT.includes(pathname)

  if (!token && !isPublic) {
    history.push(Routes.signIn)
  }

  return (
    <StyleLayout className={`${isDark ? 'theme-dark' : ''}`}>
      <Header />
      <div className={`content ${isNotHasFooter ? 'no-padding' : ''}`}>
        <div className="content-page">
          {children}
        </div>
      </div>
      {!isNotHasFooter && <Footer />}
    </StyleLayout>
  )
}

export default CustomLayout
