/* eslint-disable dot-notation */
/* eslint-disable no-new */
import 'assets/css/global.css'
import 'assets/css/animation.css'
import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import i18next from 'i18next'
import ModalConnectWallet from 'components/ModalConnectWallet'
import Layout from 'components/Layout'
import Popups from 'components/Popups'
import { EN, allLanguages } from 'constants/localization/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import { TranslationsContext } from 'hooks/TranslationsContext'
import Web3ReactManager from 'components/Web3ReactManager'
// import { useWeb3React } from '@web3-react/core'
// import { accountService, transactionService } from 'services'
// import { setAddressDeposit, setBalance, setTransactions } from 'state/account/actions'
// import { useDispatch } from 'react-redux'
// import { isAddress } from 'utils'
// import Cookies from 'js-cookie'
// import useSignMessage from 'hooks/useSignMessage'
// import useToast from 'hooks/useToast'

import { RedirectPathToSwapOnly } from './Swap/redirects'
import Home from './Home'
import SignInPage from './Auth/SignIn'
import SignUpPage from './Auth/SignUp'
import ForgotPasswordPage from './Auth/ForgotPassword'
import RechargePage from './Recharge'
import Payment from './Recharge/Payment'
import Routes from './Routes'
// import Swap from './Swap'
// import Package from './Package'
// import MyInvestments from './MyInvestments'
// import Referral from './Referral'
// import SwapOffChain from './SwapOffChain'
// import Dashboard from './Admin/Dashboard'
// import User from './Admin/User'
// import PackageAdmin from './Admin/Package'
// import ReferralAdmin from './Admin/Referral'

// import RemoveLiquidity from './RemoveLiquidity'
// import Pool from './Pool'
// import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
// import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'

const AppWrapper = styled.div`
  width: 100%;
  max-width: 576px;
  margin: 0 auto;
  overflow-x: hidden;

  background: url('/images/bg/main-bg.png') no-repeat;
  background-size: cover;
  background-position: center;
`
// Bg overview all page
const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const cacheLanguage = 'soupSwapLanguage'

export default function App() {
  // const dispatch = useDispatch()
  const { isDark, toggleTheme } = useTheme()
  // const { toastWarning } = useToast()

  // const { account, library, chainId } = useWeb3React()
  // const signMessage = useSignMessage()

  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    if (!isDark) {
      toggleTheme()
    }
  }, [isDark, toggleTheme])

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage?.code || 'en')
    if (selectedLanguage) localStorage.setItem(cacheLanguage, selectedLanguage?.code)
  }, [selectedLanguage])

  useEffect(() => {
    const storedLangCode = localStorage.getItem(cacheLanguage)
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  // useEffect(() => {
  //   if (account) {
  //     dispatch(setAddressDeposit({ walletAddress: '', isLoading: true }))
  //     dispatch(setTransactions({ docs: [], isLoading: true }))
  //     dispatch(
  //       setBalance({
  //         balance: {
  //           PBC: 0,
  //           BNB: 0,
  //           USDT: 0,
  //         },
  //         isLoading: true,
  //       })
  //     )

  //     const getBalance = () => {
  //       accountService.getBalance(
  //         account,
  //         (data) => {
  //           dispatch(
  //             setBalance({
  //               balance: data,
  //               isLoading: false,
  //             })
  //           )
  //         },
  //         () => {
  //           dispatch(
  //             setBalance({
  //               balance: {
  //                 PBC: 0,
  //                 BNB: 0,
  //                 USDT: 0,
  //               },
  //               isLoading: false,
  //             })
  //           )
  //         }
  //       )
  //     }

  //     const getListTransaction = () => {
  //       transactionService.list(
  //         account,
  //         (data) => {
  //           dispatch(setTransactions({ docs: data?.docs, isLoading: false }))
  //           getBalance()
  //         },
  //         () => {
  //           dispatch(setTransactions({ docs: [], isLoading: false }))
  //           dispatch(
  //             setBalance({
  //               balance: {
  //                 PBC: 0,
  //                 BNB: 0,
  //                 USDT: 0,
  //               },
  //               isLoading: false,
  //             })
  //           )
  //         }
  //       )
  //     }

  //     accountService.getWallet(
  //       account,
  //       (data) => {
  //         dispatch(setAddressDeposit({ walletAddress: data?.walletAddress, isLoading: false }))
  //         getListTransaction()
  //       },
  //       () => {
  //         dispatch(setAddressDeposit({ walletAddress: '', isLoading: false }))
  //         dispatch(setTransactions({ docs: [], isLoading: false }))
  //         dispatch(
  //           setBalance({
  //             balance: {
  //               PBC: 0,
  //               BNB: 0,
  //               USDT: 0,
  //             },
  //             isLoading: false,
  //           })
  //         )
  //       }
  //     )
  //   } else {
  //     dispatch(setTransactions({ docs: [], isLoading: false }))
  //     dispatch(setAddressDeposit({ walletAddress: '', isLoading: false }))
  //     dispatch(
  //       setBalance({
  //         balance: {
  //           PBC: 0,
  //           BNB: 0,
  //           USDT: 0,
  //         },
  //         isLoading: false,
  //       })
  //     )
  //   }
  // }, [account, dispatch])

  // const queryParams = new URLSearchParams(window.location.search)
  // const refParam = queryParams.get('ref')
  // const refFromCookie = Cookies.get('ref')
  // const refFromLocalStorage = localStorage.getItem('ref')
  // useEffect(() => {
  //   const checkRefSave = isAddress(refParam)

  //   if (checkRefSave && refParam) {
  //     localStorage.setItem('ref', refParam?.toLowerCase())
  //     Cookies.set('ref', refParam?.toLowerCase())
  //   }

  //   const ref = refParam?.toLowerCase() || refFromCookie || refFromLocalStorage

  //   let _paramConnect: any

  //   if (account) {
  //     if (ref && ref !== account?.toLowerCase()) {
  //       const check = isAddress(ref)

  //       if (check) {
  //         _paramConnect = {
  //           ref,
  //           walletAddress: account?.toLowerCase(),
  //         }
  //       }
  //     } else {
  //       _paramConnect = {
  //         ref: '0xBe6884fd73A45343277eC21a3f7BdedF44B06999'?.toLowerCase(),
  //         walletAddress: account?.toLowerCase(),
  //       }
  //     }
  //   }

  //   if (_paramConnect?.ref) {
  //     accountService.connect(_paramConnect)
  //   }
  // }, [account, refFromCookie, refFromLocalStorage, refParam])

  // useEffect(() => {
  //   if (account && chainId === 56) {
  //     accountService.getWallet(
  //       account,
  //       () => {
  //         return null
  //       }
  //     )
  //   }
  // }, [account, chainId, signMessage, toastWarning])

  // useEffect(() => {
  //   authService.testIp()
  // }, [])

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <AppWrapper className={isDark ? 'theme-dark' : ''}>
          <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}>
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Layout>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path={Routes.home} component={Home} />
                      <Route exact strict path={Routes.signIn} component={SignInPage} />
                      <Route exact strict path={Routes.signUp} component={SignUpPage} />
                      <Route exact strict path={Routes.forgotPassword} component={ForgotPasswordPage} />
                      <Route exact strict path={Routes.recharge} component={RechargePage} />
                      <Route exact strict path={Routes.rechargePayment} component={Payment} />

                      {/* <Route exact strict path="/packages" component={Package} />
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/my-investments" component={MyInvestments} />
                      <Route exact strict path="/referral" component={Referral} />
                      <Route exact strict path="/swap/system" component={SwapOffChain} />

                      <Route exact strict path="/admin/dashboard" component={Dashboard} />
                      <Route exact strict path="/admin/user" component={User} />
                      <Route exact strict path="/admin/package" component={PackageAdmin} />
                      <Route exact strict path="/admin/referral" component={ReferralAdmin} /> */}

                      {/* <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} /> */}

                      <Route component={RedirectPathToSwapOnly} />
                    </Switch>
                  </Web3ReactManager>
                  <ModalConnectWallet />
                </BodyWrapper>
              </Layout>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </BrowserRouter>
    </Suspense>
  )
}
